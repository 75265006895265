import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import {
  GAME_MODES,
  HEROES_TO_ARTICLE_ID,
  PLATFORMS,
  ROLES,
  SEARCH_PARAMS,
} from "@/game-marvel-rivals/constants.mjs";
import { GAME_SYMBOL_MARVEL_RIVALS } from "@/game-marvel-rivals/definition-symbol.mjs";
import type { Tierlist } from "@/game-marvel-rivals/models/tierlist.mjs";
import {
  SelectGameMode,
  SelectPlatform,
  SelectRole,
} from "@/game-marvel-rivals/Selects.jsx";
import Container from "@/shared/ContentContainer.jsx";
import DataTable from "@/shared/DataTable.jsx";
import FilterBar from "@/shared/FilterBar.jsx";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";
import PageHeader from "@/shared/PageHeader.jsx";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const REGEXP_NUMBER = /\d+(?:\.\d+)?/;

export default function Tierlist() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { searchParams } = useRoute();
  const { platform, mode, role } = useMemo(
    () => ({
      platform: searchParams.get(SEARCH_PARAMS.PLATFORM) || PLATFORMS.PC,
      mode: searchParams.get(SEARCH_PARAMS.GAMEMODE) || GAME_MODES.QUICKPLAY,
      role: searchParams.get(SEARCH_PARAMS.ROLE) || ROLES.ALL,
    }),
    [searchParams],
  );

  const {
    marvelrivals: { tierlist },
  } = useSnapshot(readState);

  const heroes = useMemo(() => {
    const roles = Object.entries(tierlist[platform] ?? {}).find((i) =>
      i[0]?.toLowerCase().includes(mode),
    );
    if (!roles) return [];
    for (const r in ROLES) {
      if (!role.includes(ROLES[r])) continue;
      // Get all heroes within a role
      return getHeroes(roles[1], ROLES[r]);
    }
    // Default to retrieving all heroes in all roles
    return getHeroes(roles[1], ROLES.ALL);
  }, [mode, platform, role, tierlist]);

  if (!heroes.length) {
    return (
      <Container className="flex column gap-4">
        <PageHeader
          image={`${appURLs.CDN}/blitz/marvelrivals/marvel-rivals-logo-colored.webp`}
          title={t(
            "marvelrivals:title.marvelRivalsHeroTierlist",
            "Marvel Rivals Hero Tierlist and Stats",
          )}
        />
        <FilterBar hiddenItems={2}>
          <SelectGameMode />
          <SelectPlatform />
          <SelectRole />
        </FilterBar>
        <Card loading style={{ height: 1200 }} />
      </Container>
    );
  }

  return (
    <Container className="flex column gap-4">
      <PageHeader
        image={`${appURLs.CDN}/blitz/marvelrivals/marvel-rivals-logo-colored.webp`}
        title={t(
          "marvelrivals:title.marvelRivalsHeroTierlist",
          "Marvel Rivals Hero Tierlist and Stats",
        )}
      />
      <FilterBar hiddenItems={2}>
        <SelectGameMode />
        <SelectPlatform />
        <SelectRole />
      </FilterBar>
      <DataTable
        indexCol
        sortable
        tallRows
        sortCol={2}
        sortColTiebreak={3}
        cols={[
          { display: t("common:heroes", "Heroes"), align: "left" },
          { display: t("common:stats.tier", "Tier"), align: "left" },
          { display: t("common:stats.winRate", "Win Rate"), isStat: true },
          { display: t("common:pickRate", "Pick Rate"), isStat: true },
        ]}
        rows={heroes.map((i) => {
          return [
            {
              display: (
                <div className="flex gap-2 align-center">
                  <img
                    src={`${appURLs.CDN_PLAIN}/blitz/marvelrivals/${i.id}.webp`}
                    alt={i.hero}
                    style={{ width: 48 }}
                  />
                  <div>{i.hero}</div>
                </div>
              ),
              value: i.hero,
              link: HEROES_TO_ARTICLE_ID[i.hero]
                ? `/${GAME_SHORT_NAMES[GAME_SYMBOL_MARVEL_RIVALS]}/article/${HEROES_TO_ARTICLE_ID[i.hero]}`
                : undefined,
            },
            {
              display: (
                <img
                  src={getTierIcon(i.tier)}
                  alt={`Tier ${i.tier}`}
                  width={28}
                  height={28}
                />
              ),
              value: i.tier,
            },
            {
              display: i.winRate.toLocaleString(language, {
                style: "percent",
              }),
              value: i.winRate * 100,
              align: "center",
            },
            {
              display: i.pickRate.toLocaleString(language, {
                style: "percent",
              }),
              value: i.pickRate * 100,
            },
          ];
        })}
      />
    </Container>
  );
}

function getHeroes(
  roles: Tierlist[string],
  key: (typeof ROLES)[keyof typeof ROLES],
) {
  const heroes: Array<Tierlist[string]["Defender"][string]> = [];
  // All roles
  if (key === ROLES.ALL) {
    for (const k in roles) {
      for (const h in roles[k]) {
        const hero = roles[k][h];
        heroes.push(hero);
      }
    }
  } else {
    // Singular role
    const k = Object.keys(roles).find((i) => i.toLowerCase() === key);
    if (k) {
      for (const h in roles[k]) {
        const hero = roles[k][h];
        heroes.push(hero);
      }
    }
  }
  return heroes.map((prev) => {
    // @ts-ignore - We're transforming the strings to numbers
    const next: {
      hero: string;
      pickRate: number;
      winRate: number;
      id: string;
      tier: number;
    } = {
      ...prev,
    };
    next.pickRate = sanitizeNumber(
      Number(prev.pickRate.match(REGEXP_NUMBER)?.[0]) / 100,
    );
    next.winRate = sanitizeNumber(
      Number(prev.winRate.match(REGEXP_NUMBER)?.[0]) / 100,
    );
    return next;
  });
}

export function meta() {
  return {
    title: [
      "marvelrivals:meta.heroes.title",
      "Marvel Rivals Hero Stats: Win Rate, Pick Rate & Rankings – Blitz Marvel Rivals",
    ],
    description: [
      "marvelrivals:meta.heroes.description",
      "Analyze Marvel Rivals hero stats, including win rates, pick rates, and rankings. Discover the strongest heroes in the meta and track performance trends to gain a competitive edge.",
    ],
    subtitle: true,
  };
}
